.logo-img{
    height: 30px;
}

.logo{
    padding: 30px;
    display: flex;
}

.title{
    color: #ffffff;
    margin-left: 20px;
    font-weight: 300;
    font-size: 20px;
    letter-spacing: 2px;
}