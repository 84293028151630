* {
  box-sizing: border-box;
} 

body{
  background-color: #5abbff
}

.wrapper{
  height:100vh;
}

.right-side{
  display: flex;
  position: sticky;
  height: 100vh;

}

 .left-side {
  background-color: #5abbff;
  height: 100vh;
}

.wrapper {
  margin: auto;
  display: flex;
  flex-wrap: wrap;
}

@media (max-width: 700px) {
  .right-side {
    flex: 0 0 100%;
    height: 50vh !Important;
  }

  .left-side {
    flex: 0 0 100%;
    height: 50vh !Important;
    overflow: scroll;
  }

  #mapDiv{
    height: 50vh;
  }
}