.sponsors-section{
    display:block;
    min-height: 160px;
    padding: 20px;
}

.sponsors img{
    margin: 10px;
}

img.logo-sm-img {
    height: 30px;
    padding: 2px;
}

img.logo-lg-img {
    height: 50px;
    padding: 2px;
}