.text-section {
  display: block;
  min-height: 160px;
  padding: 20px;
}

.text {
  color: #ffffff;
  font-weight: 200;
  font-size: 15px;
  letter-spacing: 1px;
}
